import React from 'react'
import { Box, Button, Container, Link, Typography, useTheme } from '@mui/material'
import { PhoneRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { phoneNumber } from '../../../constants/const'
import { LangSwitcher } from './LangSwitcher'

interface Props {
  hideonMobile?: boolean
  onChangeLanguage: VoidFunction
}

export const HeaderButtons: React.FC<Props> = ({ hideonMobile, onChangeLanguage }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const commonButtonStyles = {
    display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
    alignItems: 'center',
    height: { xs: '2.625em', sm: '1.875em' },
    borderRadius: theme.shape.borderRadius,
    width: { xs: '100%', sm: 'auto' },
    textDecoration: 'none',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: { xs: 'transparent', sm: theme.palette.primary.main },
        height: { xs: 'auto', sm: '1.875em' },
      }}
    >
      <Container
        maxWidth={'md'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          rowGap: '1em',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <LangSwitcher hideonMobile={!!hideonMobile} onChangeLanguage={onChangeLanguage} />
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          rowGap={'1em'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={'.5em'}
          width={'100%'}
        >
          <Button
            size='large'
            sx={{
              padding: { xs: '0', sm: '.375em .75em' },
              display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
            }}
            fullWidth={!hideonMobile}
          >
            <Link
              href={`tel:${phoneNumber}`}
              sx={{
                ...commonButtonStyles,
                justifyContent: { xs: 'center', sm: 'flex-start' },
                color: { xs: theme.palette.primary.main, sm: theme.palette.primary.contrastText },
                columnGap: '.5em',
                border: { xs: '1px solid primary.states.outlinedBorder', sm: 'none' },
              }}
            >
              <PhoneRounded sx={{ width: '1em' }} />
              <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle1' } }}>{phoneNumber}</Typography>
            </Link>
          </Button>
          <Button
            size='large'
            sx={{
              display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
            }}
            fullWidth={!hideonMobile}
          >
            <Link
              href={`${process.env.GATSBY_DASHBOARD_URL}/signin`}
              sx={{
                ...commonButtonStyles,
                justifyContent: { xs: 'center', sm: 'flex-start' },
                color: theme.palette.primary.contrastText,
                bgcolor: theme.palette.primary.main,
              }}
            >
              <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle1' } }}>{t('Header.Login')}</Typography>
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
