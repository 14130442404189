import React, { useEffect } from 'react'
import { AppBar, Box, LinearProgress } from '@mui/material'
import { HeaderButtons } from './HeaderButtons'
import { Navigation } from './Navigation'
import { useTranslation } from 'react-i18next'
import { usePageLoading } from '../../../stores/page-loading'
import { useLocation } from '@reach/router'

export const Header: React.FC = () => {
  const { i18n } = useTranslation()
  const isPageLoading = usePageLoading((state) => state.isLoading)
  const setIsPageLoading = usePageLoading((state) => state.setIsLoading)
  const location = useLocation()

  const showPageLoader = () => {
    setIsPageLoading(true)
  }

  useEffect(() => {
    if (isPageLoading) {
      setIsPageLoading(false)
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    setIsPageLoading(false)
  }, [i18n.language])

  return (
    <AppBar
      component={'header'}
      sx={{
        boxShadow: 'none',
        bgcolor: 'transparent',
        position: { xs: 'sticky', sm: 'relative' },
        zIndex: '1201',
      }}
    >
      <HeaderButtons hideonMobile onChangeLanguage={showPageLoader} />
      <Navigation onChangeLanguage={showPageLoader} />
      <Box minHeight={'0.25em'} sx={{ position: 'absolute', bottom: '0', width: '100%' }}>
        {isPageLoading && <LinearProgress />}
      </Box>
    </AppBar>
  )
}
