export const phoneNumber = '+420 228 887 034'

export const addresses = {
  prague: {
    title: 'Prague office',
    data: 'Washingtonova 1567/25, Prague',
  },
}

export const socials = {
  facebook: 'https://www.facebook.com/gostudycz/',
  instagram: 'https://www.instagram.com/gostudy_cz/',
  // linkedin: 'https://www.linkedin.com/company/gostudy',
  // twitter: 'https://twitter.com/go_study',
  youtube: 'https://www.youtube.com/channel/UCCkAlPXcfar6WmwM4ITglwg',
}

export const email = 'info@gostudy.cz'

export const pageSize = 12
