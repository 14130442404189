import { MenuItem, Select, SelectProps } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useSiteMetadata } from '../../../helpers/useSiteMetadata'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { useLocalizationStore } from '../../../stores/localization'
import { palette } from '../../../constants/themeVars'

interface Props {
  hideonMobile: boolean
  onChangeLanguage: VoidFunction
}

export const LangSwitcher: React.FC<Props> = ({ hideonMobile, onChangeLanguage }) => {
  const localizations = useLocalizationStore((store) => store.data)

  const loc = useLocation()
  const { t, i18n } = useTranslation()
  const { supportedLanguages } = useSiteMetadata()
  const curLang = i18n.language
  const selectProps: SelectProps<unknown> = hideonMobile
    ? {
        disableUnderline: true,
        variant: 'standard',
        sx: {
          display: { xs: 'none', sm: 'flex' },
          color: 'primary.contrastText',
          marginRight: '.625em',
        },
      }
    : {
        fullWidth: true,
        variant: 'outlined',
        sx: {
          display: { xs: 'flex', sm: 'none' },
          height: '2.5em',
          color: 'text.secondary',
          paddingRight: '1em',
        },
      }
  return (
    <Select
      {...selectProps}
      IconComponent={(props) => (
        <KeyboardArrowDownRounded
          {...props}
          sx={{
            color: { xs: 'text.secondary', sm: 'primary.contrastText' },
            fontSize: '1.5em',
            right: { xs: '.625em', sm: '0' },
          }}
        />
      )}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            marginTop: { xs: '0', sm: '.5em' },
            background: palette.primary?.main,
            color: palette.primary?.contrastText,
            padding: '1rem 0',
          },
          '& .MuiList-root': { padding: '0' },
          '& .MuiButtonBase-root': { padding: '0.5em 1.25em', '&:hover': { background: palette.secondary?.states?.focus } },
        },
      }}
      value={curLang}
      onChange={(e) => {
        onChangeLanguage()
        navigate(loc.pathname.replace(loc.pathname.slice(0, 3), `/${e.target.value}`))
      }}
    >
      {supportedLanguages.map((lang: string, index: number) => (
        <MenuItem key={index} value={lang} sx={{ height: { xs: '2.5em', sm: 'auto' } }}>
          {t(`LanguageSwitcher.${lang}`)}
        </MenuItem>
      ))}
    </Select>
  )
}
