import React from 'react'
import { Container, Divider, useTheme } from '@mui/material'
import { Terms } from './Terms'
import { FooterTop } from './FooterTop'

export const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <Container
      component={'footer'}
      maxWidth={'md'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: { xs: '1.25em', sm: '1.75em' },
        bgcolor: theme.palette.background.paper,
        paddingTop: { xs: '1.25em', sm: '3.25em' },
        paddingBottom: { xs: '1.25em', sm: '1.75em' },
      }}
    >
      <FooterTop />
      <Divider />
      <Terms />
    </Container>
  )
}
