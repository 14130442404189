import { Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Terms: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary} textAlign={'center'}>
        {t('© Go Study group jazyková škola s právem státní jazykové zkoušky s.r.o. 2023. All Rights Reserved.')}
      </Typography>
      {/* <Stack sx={{ flexDirection: 'row', columnGap: '1.5em' }}>
        <Link href='#'>
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {t('Privacy Policy')}
          </Typography>
        </Link>
        <Link href='#'>
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {t('Terms & Conditions')}
          </Typography>
        </Link>
      </Stack> */}
    </Stack>
  )
}
