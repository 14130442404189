import React from 'react'
import { Container, useTheme } from '@mui/material'
import Logo from '../../../images/svg/mainLogoText.svg'
import { Link as NavLink } from 'gatsby'
import { HeaderDrawer } from './Drawer'
import { NavigationList } from './NavigationList'
import { useTranslation } from 'react-i18next'

interface Props {
  onChangeLanguage: VoidFunction
}

export const Navigation: React.FC<Props> = ({ onChangeLanguage }) => {
  const theme = useTheme()
  const { i18n } = useTranslation()

  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: { xs: '4.5em', sm: '3.75em' },
        bgcolor: theme.palette.background.paper,
      }}
    >
      <NavLink to={`/${i18n.language}/`} style={{ textDecoration: 'none', height: '1.25rem' }}>
        <Logo />
      </NavLink>
      <NavigationList hideonMobile />
      <HeaderDrawer onChangeLanguage={onChangeLanguage} />
    </Container>
  )
}
