export interface PageListItem {
  path: string
  template: string
  matchPath?: string
  defaultContext?: object
}
export const pagelist: { [key: string]: PageListItem } = {
  residence: { path: '/residences/__slug__', template: 'residence', matchPath: '/residences/:slug' },
  residences: { path: '/residences', template: 'residences' },
  // programs: { path: '/catalog/programs', template: 'programs' },
  // program: { path: '/catalog/programs/__id__', template: 'program', matchPath: '/catalog/programs/:id' },
  // services: { path: '/catalog/services', template: 'services' },
  // service: { path: '/catalog/services/__id__', template: 'service', matchPath: '/catalog/services/:id' },
  // mainPage: { path: '/', template: 'main' },
  residencesLandingPage: { path: '/', template: 'residences-landing' },
  // media: { path: '/media', template: 'media', defaultContext: { currentCategory: { slug: '*' } } },
}
export default Object.values(pagelist)
