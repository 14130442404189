/* eslint-disable react/hook-use-state */
import React from 'react'
import { Box, Button, Divider, Link, List, Typography, useTheme } from '@mui/material'
import {
  // ArticleRounded, AutoAwesomeMotionRounded,
  HomeWorkRounded,
  KeyboardBackspaceRounded,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link as NavLink } from '../../Link'
import { pagelist } from '../../../../pagelist.data'
import { usePageLoading } from '../../../stores/page-loading'
import { useLocation } from '@reach/router'
// import { EducationalCenter } from './EducationalCenter'
// import { AboutUs } from './AboutUs'
import { resources } from '../../../constants/langConstants'

//isLink
export type NavOption = {
  isLink: boolean
  title?: keyof typeof resources.en.translation
  to?: string
  component: JSX.Element
  external?: boolean
  onClick?: () => void
}

export const NavigationList: React.FC<{ hideonMobile?: boolean; onLinkClick?: () => void }> = ({
  hideonMobile,
  onLinkClick,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const setIsPageLoading = usePageLoading((state) => state.setIsLoading)

  const handleLinkClick = (to: string) => {
    if (!location.pathname.includes(to)) {
      setIsPageLoading(true)
    }

    onLinkClick && onLinkClick()
  }

  const navOptions: NavOption[] = [
    // {
    //   isLink: false,
    //   component: <EducationalCenter />,
    // },
    // {
    //   isLink: true,
    //   title: 'Header.AllPrograms',
    //   to: `/${i18n.language}${pagelist.programs.path}`,
    //   component: (
    //     <AutoAwesomeMotionRounded
    //       sx={{
    //         color: 'primary.states.outlinedBorder',
    //         width: '1.25em',
    //         transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    //       }}
    //     />
    //   ),
    //   external: false,
    // },
    {
      isLink: true,
      title: 'Header.BackToGoStudy',
      to: process.env.GATSBY_OLD_SITE_URL,
      component: (
        <KeyboardBackspaceRounded
          sx={{
            color: 'primary.states.outlinedBorder',
            width: '1.25em',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        />
      ),
      external: true,
    },
    {
      isLink: true,
      title: 'Header.Residences',
      to: `/${i18n.language}${pagelist.residences.path}`,
      component: (
        <HomeWorkRounded
          sx={{
            color: 'primary.states.outlinedBorder',
            width: '1.25em',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        />
      ),
      external: false,
    },
    // {
    //   isLink: true,
    //   title: 'Header.Media',
    //   to: `/${i18n.language}${pagelist.media.path}`,
    //   component: (
    //     <ArticleRounded
    //       sx={{
    //         color: 'primary.states.outlinedBorder',
    //         width: '1.25em',
    //         transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    //       }}
    //     />
    //   ),
    //   external: false,
    // },
    // {
    //   isLink: false,
    //   component: <AboutUs />,
    // },
  ]

  const renderLink = (navLink: NavOption) => {
    if (navLink.external) {
      return (
        <Button
          size='large'
          sx={{
            display: { xs: 'block', sm: 'flex' },
            padding: '0',
            '&:hover svg': { color: 'inherit' },
          }}
        >
          <Link
            href={navLink.to}
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              columnGap: '.5em',
              py: '.5rem',
              px: '.6875rem',
            }}
          >
            {navLink.component}
            <Typography variant={'subtitle2'} sx={{ color: theme.palette.text.primary }}>
              {navLink.title && t(navLink.title)}
            </Typography>
          </Link>
        </Button>
      )
    }
    return (
      <Button size='large' sx={{ display: { xs: 'block', sm: 'flex' }, '&:hover svg': { color: 'inherit' }, p: 0 }}>
        <NavLink
          to={navLink.to as string}
          onClick={handleLinkClick}
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            columnGap: '.5em',
            py: '.5rem',
            px: '.6875rem',
          }}
        >
          {navLink.component}
          <Typography variant={'subtitle2'} sx={{ color: theme.palette.text.primary }}>
            {navLink.title && t(navLink.title)}
          </Typography>
        </NavLink>
      </Button>
    )
  }

  return (
    <List
      sx={{
        display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
        flexDirection: { xs: 'column', sm: 'row' },
        rowGap: '.75em',
        columnGap: '1em',
        padding: { xs: '0 1em', sm: '0' },
      }}
    >
      {navOptions.map((item, index) => (
        <Box key={item.to} sx={{ display: 'flex', flexDirection: 'column', rowGap: '.75em' }}>
          {item.isLink ? renderLink(item) : item.component}
          {!hideonMobile && index !== navOptions.length - 1 && <Divider />}
        </Box>
      ))}
    </List>
  )
}
