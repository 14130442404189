import React, { ReactElement } from 'react'
import { Box, Link, Stack, Typography, useTheme } from '@mui/material'
import Logo from '../../../images/svg/mainLogoText.svg'
import { addresses, email, phoneNumber, socials } from '../../../constants/const'
import { Facebook, Instagram, LinkedIn, MailRounded, PhoneRounded, Twitter, YouTube } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export const Contacts: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const commonButtonStyles = {
    display: 'flex',
    columnGap: '.625em',
    textDecoration: 'none',
    color: 'primary.states.outlinedBorder',
  }

  const addressesForDisplay = Object.values(addresses).map((address) => (
    <Stack
      key={address.title}
      sx={{
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start' },
        textAlign: { xs: 'center', sm: 'left' },
      }}
    >
      <Typography variant={'subtitle2'}>{t(address.title)}</Typography>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
        {t(address.data)}
      </Typography>
    </Stack>
  ))

  const socialIcons: { [key: string]: { component: ReactElement; hoverColor: string } } = {
    facebook: { component: <Facebook />, hoverColor: '#3B5998' },
    instagram: {
      component: <Instagram />,
      hoverColor: `radial-gradient(
      circle farthest-corner at 28% 100%,
      #fcdf8f 0%,
      #fbd377 10%,
      #fa8e37 22%,
      #f73344 35%,
      transparent 65%
    ), #c92bb7`,
    },
    linkedin: { component: <LinkedIn />, hoverColor: '#0077B5' },
    twitter: { component: <Twitter />, hoverColor: '#1D9BF0' },
    youtube: { component: <YouTube />, hoverColor: '#FF0000' },
  }

  const getSocialIconButton = (socialName: string, socialUrl: string) => {
    return (
      <Link
        href={socialUrl}
        key={socialName}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.background.paper,
          bgcolor: 'primary.states.outlinedBorder',
          width: '2em',
          height: '2em',
          borderRadius: '100%',
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            background: socialIcons[socialName].hoverColor,
          },
        }}
      >
        {socialIcons[socialName].component}
      </Link>
    )
  }

  const socialsForDisplay = Object.entries(socials).map(([socialName, socialUrl]) =>
    getSocialIconButton(socialName, socialUrl)
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'center', sm: 'flex-start' },
        rowGap: '1em',
        maxWidth: { xs: '100%', sm: '14.6875em' },
        marginBottom: { sm: '.75em' },
      }}
    >
      <Logo />
      <Stack sx={{ width: '100%', flexDirection: 'column', rowGap: '.5em', marginTop: { xs: '.5em', sm: '1em' } }}>
        {addressesForDisplay}
      </Stack>
      <Stack
        sx={{
          width: '100%',
          flexDirection: { xs: 'row', sm: 'column' },
          justifyContent: 'center',
          alignItems: { xs: 'center', sm: 'flex-start' },
          rowGap: '.5em',
          columnGap: '1.5em',
        }}
      >
        <Link href={`mailto:${email}`} sx={commonButtonStyles}>
          <MailRounded sx={{ width: '1em' }} />
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {email}
          </Typography>
        </Link>
        <Link href={`tel:${phoneNumber}`} sx={commonButtonStyles}>
          <PhoneRounded sx={{ width: '1em' }} />
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {phoneNumber}
          </Typography>
        </Link>
      </Stack>
      <Stack sx={{ flexDirection: 'row', columnGap: '.75em' }}>{socialsForDisplay}</Stack>
    </Box>
  )
}
