import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { IconButton, useTheme } from '@mui/material'
import { MenuOpenRounded, MenuRounded } from '@mui/icons-material'
import { NavigationList } from './NavigationList'
import { HeaderButtons } from './HeaderButtons'

interface Props {
  onChangeLanguage: VoidFunction
}

export const HeaderDrawer: React.FC<Props> = ({ onChangeLanguage }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const theme = useTheme()

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen((prev) => !prev)
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  const content = () => (
    <Box
      sx={{
        height: 'calc(100% - 4.5em)',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1em 0',
        position: 'absolute',
        width: '100%',
        top: '4.5rem',
      }}
      role='presentation'
    >
      <NavigationList onLinkClick={closeDrawer} />
      <HeaderButtons onChangeLanguage={onChangeLanguage} />
    </Box>
  )

  return (
    <>
      <IconButton
        onClick={toggleDrawer()}
        sx={{ color: theme.palette.primary.main, fontSize: '1.5em', display: { sm: 'none' }, padding: '0' }}
      >
        {isOpen ? <MenuOpenRounded /> : <MenuRounded />}
      </IconButton>
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer()}
        sx={{ position: 'unset' }}
        hideBackdrop
        PaperProps={{ sx: { boxShadow: 'none', width: '100%', justifyContent: 'space-between' } }}
        disableRestoreFocus
      >
        {content()}
      </Drawer>
    </>
  )
}
