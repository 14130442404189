import { Link as NavLink } from 'gatsby'
import { Link as LinkMui } from '@mui/material'
import React, { Ref, forwardRef } from 'react'
import { SxProps } from '@mui/system'

type Props = { to: string; sx?: SxProps; onClick?: (to: string) => void }

export const Link = forwardRef((props: React.PropsWithChildren<Props>, ref: Ref<NavLink<unknown>>) => {
  const { onClick, ...restProps } = props
  return (
    <LinkMui
      component={NavLink}
      {...restProps}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick(props.to)
      }}
    >
      {props.children}
    </LinkMui>
  )
})

Link.displayName = 'Link'
